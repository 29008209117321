.side-panel {
  .react-select {
    background: var(--Neutral-20);
    color: var(--Neutral-90);
    width: 193px;
    height: 30px;

    .react-select__single-value {
      color: var(--Neutral-90);
    }

    .react-select__menu {
      background: var(--Neutral-20);
      color: var(--Neutral-90);
    }

    .react-select__option {
    }
    .react-select__value-container {
    }
    .react-select__indicators {
    }
    .react-select__option:hover,
    .react-select__option:focus,
    .react-select_option:focus-within {
      background: var(--Neutral-30);
    }
    .react-select__option--is-focused: {
      background: var(--Neutral-30);
    }
  }
  background: var(--Neutral-00);
  width: 40px; /* when closed */
  display: flex;
  flex-direction: column;
  height: 100vh;
  transition: all 0.2s ease-in;
  font-family: Arial, sans-serif;
  border-right: 1px solid var(--gray-600);
  color: var(--Neutral-90, #e1e2e3);
  font-family: var(--font-family);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 20.8px */

  .hidden {
    display: none !important;
  }

  &.open {
    .top {
      h2 {
        left: 0%;
        display: block;
        opacity: 1;
      }
    }
  }

  .top {
    display: flex;
    width: calc(100% - 45px);
    justify-content: space-between;
    align-items: center;
    padding: 12px 20px 12px 25px;
    border-bottom: 1px solid var(--Neutral-20);

    h2 {
      position: relative;
      color: var(--Neutral-90, #e1e2e3);
      font-family: "Google Sans";
      font-size: 21px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 100% */

      opacity: 0;
      display: none;
      left: -100%;
      transition:
        opacity 0.2s ease-in,
        left 0.2s ease-in,
        display 0.2s ease-in;
      transition-behavior: allow-discrete;

      @starting-style {
        left: 0%;
        opacity: 1;
      }
    }
  }

  .opener {
    height: 30px;
    transition: transform 0.2s ease-in;
  }

  &:not(.open) {
    .side-panel-container {
      opacity: 0;
      display: none;
      transition: all 0.2s ease-in allow-discrete;
      transition-delay: 0.1s;
    }

    .indicators .streaming-indicator {
      width: 30px;
      opacity: 0;
    }

    .opener {
      transform: translate(-50%, 0);
    }

    .input-container {
      opacity: 0;
      display: none;
      transition: all 0.2s ease-in allow-discrete;
    }
  }

  .indicators {
    display: flex;
    padding: 24px 25px;
    justify-content: flex-end;
    gap: 21px;
    .streaming-indicator {
      user-select: none;
      border-radius: 4px;
      border: 1px solid var(--Neutral-20, #2a2f31);
      background: var(--Neutral-10, #1c1f21);
      display: flex;
      width: 136px;
      height: 30px;
      padding-left: 4px;
      justify-content: center;
      align-items: center;
      gap: 6px;
      flex-shrink: 0;
      text-align: center;
      font-family: "Space Mono";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      transition: width 0.2s ease-in;

      &.connected {
        color: var(--Blue-500, #0d9c53);
      }
    }
  }

  .side-panel-container {
    align-self: flex-end;
    width: 400px;
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: auto;
    /*scrollbar-gutter: stable both-edges;*/
  }

  .input-container {
    height: 50px;
    flex-grow: 0;
    flex-shrink: 0;
    border-top: 1px solid var(--Neutral-20);
    padding: 14px 25px;
    overflow: hidden;

    .input-content {
      position: relative;
      background: var(--Neutral-10);
      border: 1px solid var(--Neutral-15);
      height: 22px;
      border-radius: 10px;
      padding: 11px 18px;

      .send-button {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0, -50%);
        background: none;
        border: 0;
        color: var(--Neutral-20);
        cursor: pointer;
        transition: color 0.1s ease-in;
        z-index: 2;

        &:hover {
          color: var(--Neutral-60);
        }
      }

      .input-area {
        background: none;
        color: var(--Neutral-90);
        field-sizing: content;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        display: inline-block;
        width: calc(100% - 72px);
        max-height: 20px;
        outline: none;
        --webkit-box-flex: 1;
        flex: 1;
        word-break: break-word;
        overflow: auto;
        padding: 14px 18px;
        border: 0;
        resize: none;
      }

      .input-content-placeholder {
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        z-index: 1;
        height: 100%;
        width: 100%;
        pointer-events: none;
        user-select: none;
        padding: 0px 18px;
        white-space: pre-wrap;
      }
    }
  }
}
.side-panel.open {
  width: 400px;
  height: 100vh;
  display: none;
}

.side-panel-responses,
.side-panel-requests {
  flex-grow: 1;
  flex-shrink: 1;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  display: block;
  margin-left: 8px;
}

.top {
  width: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  height: 30px;
  display: flex;
  align-self: flex-end;
  align-items: center;
  transition: all 0.2s ease-in;
}
.top button {
  background: transparent;
  border: 0;
  cursor: pointer;
  font-size: 1.25rem;
  line-height: 1.75rem;
  padding: 4px;
}
